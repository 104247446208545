.clear-both {
    clear: both;
}

.cart-thumb {
    position: relative;
    margin-bottom: 50px !important;
}

.cart-thumb-image {
    display: inline-block;
    float: left;
    width: 200px;
}

.cart-thumb-info {
    padding-left: 30px;
    float: left;
}

.cart-thumb-text {
    font-size: 20px;
    color: #000;
}

.cart-thumb-header {
    font-size: 25px;
    font-weight: 500;
    color: #000;
    margin-bottom: 30px;
}

.cart-thumb-sep {
    background-color: #000;
    border: 0 none;
    color: #000;
    height: 1px;
    width: 65%;
}

.cart-remove-button {
    position: absolute;
    width: 40px;
    height: 40px;
    top: 0;
    right: 35%;
}

@media only screen and (max-width: 768px) {
    .cart-thumb-image {
        clear: both;
        width: auto;
        padding-left: 5%;
        padding-right: 5%;
    }

    .cart-thumb-header {
        margin-bottom: 10px;
    }

    .cart-thumb-info {
        padding-left: 5%;
        text-align: left;
    }

    .cart-total-price {
        margin-top: 100px;
    }

    .cart-thumb-sep {
        width: 90%;
        margin-left: 5%;
        margin-right: 5%;
    }

    .cart-remove-button {
        position: absolute;
        width: 40px;
        height: 40px;
        top: 0;
        right: 5%;
    }
}
