.image-gallery {
    float: left;
    margin-bottom: 100px;
    margin-left: 50px;
}

.image-gallery-wrapper {
    width: 1000px;
}

.image-gallery-img {
    display: block;
    margin-left: auto;
    margin-right: auto;
    padding-bottom: 50px;
    padding-right: 100px;
}

.image-gallery-link-wrapper {
    font-size: 25px;
    color: #000 !important;
    letter-spacing: 5px;
    text-transform: uppercase;
    text-decoration: underline;

    text-align: center;
    padding-right: 80px;
}

@media only screen and (max-width: 768px) {
    .image-gallery {
        clear: both;
        margin-left: 0;
        width: 100vw;
    }

    .image-gallery-wrapper {
        display: block;
        width: auto;
    }

    .image-gallery-img {
        padding-right: 5%;
        padding-left: 5%;
        padding-bottom: 30px;
    }

    .image-gallery-link-wrapper {
        padding-right: 0px;
    }

    .image-gallery-link {
        text-align: center;
    }
}
