.lookbook-link-wrapper {
    margin-top: 100px;
    margin-left: 23%;
}

.lookbook-link {
    font-size: 25px;
    letter-spacing: 5px;
    color: #000 !important;
    text-decoration: underline;
    text-transform: uppercase;
}

@media only screen and (max-width: 768px) {
    .lookbook-link-wrapper {
        margin-top: 0px;
        margin-left: 0px;
        text-align: center;
        text-decoration: underline;
    }

    .lookbook-link {
        letter-spacing: 3px;
    }
}
