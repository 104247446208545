.nav-links {
    padding-left: 0;
    list-style: none;
    margin-bottom: 50px;
}

.nav-link {
    color: #000;
    padding-bottom: 7px;
    font-size: 20px;
    font-weight: 500;
    letter-spacing: 3px;
}

.nav-newsletter {
    margin-top: -45px;
    border: 2px solid black;
    padding: 10px;
    width: 170px;
    height: 30px;
    font-size: 15px;
    color: #000;
    font-weight: 500;
    text-align: center;
    letter-spacing: 3px;
}

::placeholder {
    text-align: center;
    font-weight: 700;
    font-size: 15px !important;
    color: #000;
}

@media only screen and (max-width: 768px) {
    .nav-link {
        position: relative;
        text-align: center;
    }
}
