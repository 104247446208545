.shipping-wrapper {
}

.shipping-btn-wrapper {
    padding-bottom: 40px;
}

.cart-total-price {
    margin-top: -30px;
    color: #000;
    font-weight: 700;
}

.cart-total-price-invalid {
    margin-top: -30px;
    color: #f44242;
    font-weight: 700;
}

.input {
    border: 2px solid black;
    padding: 10px;
    margin-bottom: 10px;
    width: 191px;
    height: 5px;
    font-size: 15px;
    color: #000;
    font-weight: 500;
}

.checkout-btn {
    margin-top: 20px;
    margin-bottom: 10px;
}

::placeholder {
    text-align: center;
    font-weight: 600;
    font-size: 15px !important;
    color: #000;
}

#paypal-button-wrapper {
    width: 55%;
}

.payment-text {
    font-size: 12px;
}

.shipping-btn {
    font-size: 15px;
}

.input:focus::-webkit-input-placeholder { color:transparent; }
.input:focus:-moz-placeholder { color:transparent; } /* FF 4-18 */
.input:focus::-moz-placeholder { color:transparent; } /* FF 19+ */
.input:focus:-ms-input-placeholder { color:transparent; } /* IE 10+ */

.invalid {
    border-color: #f44242;
}

@media only screen and (max-width: 768px) {
    #paypal-button-wrapper {
        margin-left: auto;
        margin-right: auto;
    }
}
