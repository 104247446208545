.back-to-shop-btn {
    font-size: 15px !important;
}

.content-loader {
    position: fixed !important;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
