.action-button {
    width: 215px;
    height: 55px;
    border: 2px solid black;

    font-size: 20px;
    font-weight: 700;
    letter-spacing: 2px;
    text-align: center;
}

@media only screen and (max-width: 768px) {
    .newsletter {
        display: inline-block;
    }
}
