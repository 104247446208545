.archive-wrapper {
}

.archive-title {
    font-weight: 600;
    color: #000;
    margin-left: 33%;
    margin-bottom: 150px;
}

.archive-list {
    margin-left: -20%;
    text-align: center;
    list-style-type: none;
}

.archive-link {
    display: block;
    font-size: 25px;
    font-weight: 500;
    letter-spacing: 5px;
    color: #000 !important;
    padding-bottom: 30px;
    text-decoration: underline;
}

@media only screen and (max-width: 768px) {
    .archive-title {
        margin-left: 0px;
        text-align: center;
        margin-bottom: 70px;
    }

    .archive-list {
        margin-left: 0px;
        padding-left: 0px;
    }

    .archive-link {
        text-decoration: underline;
        padding-bottom: 50px;
    }
}
