.logo {
    font-size: 30px;
    font-style: italic;
    font-weight: 900;
    letter-spacing: 5px;
    color: #000;
}

@media only screen and (max-width: 768px) {
    .logo {
        text-align: center;
    }
}
