.aside-wrapper {
    position: fixed;
    top: 0;
    left: 0;

    display: inline-block;
    float: left;

    height: 100%;

    margin-left: 30px;
    margin-top: 30px;
}

@media only screen and (max-width: 768px) {
    .aside-wrapper {
        position: static;
        padding-left: 0px;
        text-align: center;
        margin-left: 0px;
        margin-bottom: 50px;
        height: auto;
    }
}
