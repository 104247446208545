.product-thumb {
    width: 1000px;
    padding-right: 50px;
    padding-bottom: 50px;
}

.product-thumb-img {
    display: block;
    margin-left: auto;
    margin-right: auto;
    height: 700px;
}

@media only screen and (max-width: 768px) {
    .product-thumb {
        clear: both;
        display: block;
        padding-right: 0px;
        width: 100vw;
    }

    .product-thumb-img {
        padding-left: 5%;
        padding-right: 5%;
        height: auto;
    }
}
