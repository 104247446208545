.content-wrapper {
    margin-top: 40px;
    padding-left: 25%;
    float: left;
    overflow: hidden;
    margin-bottom: 100px;
}

.mobile-cart-wrapper {
    display: none;
}

@media only screen and (max-width: 768px) {
    .content-wrapper {
        margin-top: 0px;
        padding-left: 0px;
        clear: both;
    }

    .mobile-cart-wrapper {
        display: block;
    }

    .mobile-cart {
        position: fixed;

        z-index: 100;

        bottom: 45px;
        left: 8px;
        width: 30px;
        height: 30px;
    }
}
