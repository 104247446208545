.footer {
    position: fixed;
    bottom: 0;
    background-color: yellow;
    width: 100%;
    height: 30px;
    padding-top: 5px;
}

.footer ul {
    display: inline-block;
    list-style: none;
}

.footer ul li {
    float: left;
    font-style: italic;
    text-decoration: underline;
    margin-right: 10px;
}

.footer a {
    color: #000;
}

.copyright {
    float: right;
    margin-right: 30px;
    color: #000;
    font-style: italic;
    text-decoration: underline;
}

@media only screen and (max-width: 768px) {
    .footer {
        font-size: 12px;
        height: 75px;
        text-align: center;
    }

    .footer ul {
        margin-left: 20px;
        margin-bottom: 0;
    }

    .copyright {
        clear: both;
        text-align: center;
        margin-top: -5px;
        padding-left: 15px;
    }
}
