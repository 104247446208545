.product-info {
    margin-top: -40px;
}

.product-name {
    font-weight: 500;
    font-size: 20px;
}

.product-desc {
    font-size: 13px;
}

.product-size-chart {
    font-size: 15px;
    text-decoration: underline;
}

.product-sizes {
    padding-left: 0;
    margin-top: 5px;
    list-style-type: none;
}

.clear-both {
    clear: both;
}

.product-size {
    float: left;
    padding: 11px 20px;
    border: 1px solid black;
    font-weight: 800;
}

.product-size:hover {
    background-color: #DDD;
    color: #FFF;
}

.product-size-selected {
    background-color: #DDD;
    color: #FFF;
}

.product-price {
    font-weight: 500;
    font-size: 18px;
    margin-top: 15px;
}

.product-add-to-cart {
    margin-top: 10px;
    margin-bottom: 20px;
    font-size: 15px;
}

.product-sold-out {
    font-size: 20px;
    font-weight: 500;
    margin-top: 75px;
    color: #222;
}

@media only screen and (max-width: 768px) {
    .product-sizes {
        margin-left: 75px;
    }
}
