.purchase-text {
    margin-top: 200px;
    padding-left: 40px;
    color: #000;
    font-weight: 500;
    font-size: 25px;
}

.purchase-shop-link {
    display: block;
    font-size: 25px;
    font-weight: 500;
    letter-spacing: 5px;
    color: #000 !important;
    padding-bottom: 30px;
    text-decoration: underline;
    text-align: center;
    margin-left: -140px;
}
