.padding-left {
    padding-left: 50px;
}

.section-title {
    text-align: left;
    font-family: 'franklin', sans-serif;
    font-style: italic;
    text-transform: uppercase;
    color: rgba(0, 0, 0, 1);
}

.section-text-left {
    font-size: 1.3em;
    text-align: left;
}

.underlined-link {
    text-decoration: underline;
}
